<template>
  <div class="theExperience">
    <section>
      <article>
        <div class="container">
          <div class="articleOne d-flex align-items-end pb-3">
            <div class="row">
              <div class="col-md-8">
                <h2>The East African Experiences.</h2>
                <p>
                  East Africa is a vibrant and diverse region with a rich culture and stunning natural beauty. It is a popular destination for tourists looking to experience the unique sights and sounds of Africa. Some of the top tour experiences in East Africa include safari adventures in the savannah, trekking in the mountains, and exploring the vibrant cities and towns.
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="container">
        <div
          v-if="isLoading || experiences.length == 0"
          class="px-0 mt-3 col-12"
        >
          <div class="products_skeletons">
            <div
              v-for="i in 6"
              :key="i"
              class="skeleton-block skeleton-effect-wave"
            ></div>
          </div>
        </div>
        <div
          v-else
          class="experience_grid mb-5"
        >
          <div
            v-for="experience in experiences"
            :key="experience.id"
          >
            <img
              :src="experience.image"
              class="d-block w-100"
              alt="..."
            />
            <div class="experience_description text-center">
              <h2>{{ experience.title }}</h2>
              <p class="d-none d-md-block">
                {{ experience.description | truncate(80, '...') }}
              </p>
              <p class="d-md-none d-sm-block">
                {{ experience.description | truncate(40, '...') }}
              </p>
              <div class="text-center mt-3">
                <el-button
                  style="background-color: #233A2E; color: white;"
                  @click="$router.push({ path: `/experience/${experience.id}` })"
                >Show Tours <i class="el-icon-d-arrow-right"></i></el-button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isLoading: false,
      isLoadingError: false,
      experiences: [],
    };
  },

  mounted() {
    this.getExperiences();
  },

  methods: {
    async getExperiences() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`api/experience/get_all`);
        if (request.data.success) {
          this.experiences = request.data.experiences;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>


<style scoped>
.theExperience {
  background-color: #f0f9eb;
  padding-top: 100px;
  padding-bottom: 100px;
}
.articleOne h2 {
  font-size: 2em;
  font-weight: 600;
  color: #233a2e;
}
.articleOne p {
  color: #233a2e;
  font-size: 1em;
  font-weight: 300;
}
.experience_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.experience_grid > div {
  height: 500px;
  position: relative;
  border-radius: 5px;
}
.experience_grid > div img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.experience_description {
  border-radius: 5px;
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  padding: 50px 20px;
  background-image: linear-gradient(rgba(16, 50, 48, 1), rgba(16, 50, 48, 0));
  color: white;
}
.experience_description h2 {
  font-size: 1.2em;
  font-weight: 600;
}
.experience_description p {
  font-size: 1em;
  font-weight: 300;
}
.products_skeletons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.products_skeletons > div {
  height: 500px;
  border-radius: 5px;
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .experience_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .experience_grid > div {
    height: 300px;
  }

  .experience_grid > div img {
    height: 300px;
  }
  .products_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .experience_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .experience_grid > div {
    height: 300px;
  }

  .experience_grid > div img {
    height: 300px;
  }
  .products_skeletons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .articleOne h2 {
    font-size: 1.5em;
    font-weight: 600;
    color: #233a2e;
  }
  .articleOne p {
    color: #233a2e;
    font-size: 1em;
  }
  .experience_description h2 {
    font-size: 1em;
    font-weight: 600;
  }
  .experience_description p {
    font-size: 0.8em;
    font-weight: 300;
  }
}
</style>